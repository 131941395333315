
.small {
    .Package {
        grid-template-areas:
            "icon   title  title"
            "icon   rstats value"
            /*"pstats rstats value"*/
            ;
        grid-template-columns: 38px 1fr 32px;
    }
    .code-quality { display: none; }
    .project-data {display: none;}
    tfoot { display: none }
    .sprkline { display: none;}
    .CC {display: none;}
    .LOC {display: none;}
    .run-data-box tr {display: none}
    .run-data-box tbody tr:first-child {display: table-row}
    .score {font-size: 16px;}
    .avatar { height: 38px; max-width: 38px; }
}
