
:root {
    --border-color: #eee;
    --run-data-font-size: 10px;

    --size: 12px;

    --background: #ffffff;
    --green: #56cb79;
    --good-color: #56cb79;
    --red: rgba(249, 94, 87, 0.77);
    --bad-color: rgba(249, 94, 87, 0.77);
}

.theme-dark {
    --background: ##36454f;
    --green: #48ac66;
    --good-color: #48ac66;
    --red: rgba(249, 94, 87, 0.77);
    --bad-color: rgba(249, 94, 87, 0.77);
}

html {
    font-family: Calibri, sans-serif;
}

.score { color: #222; }

// .success {
//     //border-color: green;
//     //background-color: lightgreen;
// }

.failed {
    background-color: #ff815a82;
}

.value:after {color: #444;}
.dark .value:after {color: #eee;}

.duration-box {color: #222;}
.dark .duration-box {color: #eee;}

button {
    border: 1px solid #999;
    border-radius: 3px;
    margin-right: 2px;

    &.active {
        background-color: #666;
        border-color: #666;
        color: white;

        &.asc::before {
            content: "+"
        }

        &.desc::before {
            content: "-"
        }
    }
}
