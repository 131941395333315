@import "page-layout";
@import "theme";
@import "widgets";
@import "small";

.duration {
    margin-right: 2ex;
}

.value-list {
    display: inline-grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1px 1ex;
}

.value-label {
    font-weight: bold;
    color: #666;
}

.value-label:after {
    content: ":";
}

.test-data {
    width: 100px;
    display: inline-block;
}

.test-data > div {
    float: left;
    height: 15px;
}

.content {
    padding-left: 10px;
}

.content > h3 {
    display: inline-block;
}

.run-data-box table { width: 100%; border-collapse: collapse; font-size: 11px }
.run-data-box td {text-align: center; border: 1px solid var(--border-color); min-width: 2em;}
.run-data-box th {text-align: center; border: 1px solid var(--border-color)}
.run-data-box tfoot td {border:none;}
/*.content>h3:first-child {margin-left:-10px;margin-bottom: 3px;}*/

.project-data .value {
    font-size: 12px;
    display: inline-block;
}


.code-quality {font-size:10px}
.value:after {
    font-size: 85%;
    font-variant: small-caps;
    margin-left: 1px;
}

.LOC:after {
    content: "loc";
}

.LOC.kloc:after {
    content: "kloc";
}

.MI:after {
    content: "MI";
}

.CC:after {
    content: "CC";
}

.pylint-box:after {
    content: "lnt"
}


.coverage:after {
    content: "coverage"
}

.content {
    text-align: right;
    line-height: 0;
}

.content + .content {
    border-top: 1px dotted #999;
}

.green {
    background-color: var(--green);
    color: white;
}

.red {
    background-color: var(--red);
    color: white;
}

span.label {
    color: #999;
    font-size: 90%;
}

.no-results {
    background-color: #ededed;
}
