

html { height: 100%; }
body { height: 100vh; }
html { box-sizing: border-box; }

.packages {
    height: 100vh;

    .cmdline { height: 22px;}
    .package-box { height: calc(100% - 40px); }
}

.Package {
    display: inline-grid;
    grid-template-areas:
        "icon         title    "
        "icon         rstats   "
        "value        pstats   "
;
    grid-template-columns: 45px auto;
    grid-column-gap: 6px;
}

.code-quality {grid-area: cq; text-align: right; }

// .short-stats {
//     grid-area: pstats;
//     font-size: var(--size);
// // outline: 1px solid red;
//     display: flex;
//     // display: grid;
//     // grid-template-areas: 
//     //     "pipeline   ."
//     //     "MI         CI"    
//     // ;
// }
// .short-stats > div {

// // outline: 1px solid blue;
//     text-align: center;
//     width: 18px;
// }

.avatar-link {
    display: block;
    grid-area: icon;
    align-self: center;
    // padding: 4px;
    // width: 60px;
// outline: 1px solid yellow;
}

.title {
    grid-area: title;
    align-self: start;
}

.project-data {
    grid-area: pstats;
    align-self: end;
    justify-self: end;
}

.run-data-box {
    grid-area: rstats;
}

.status {
    grid-area: value;
    text-align: end;
}
