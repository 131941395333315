.Package {
    border: 1px solid #666;
    margin: 2px;
    //padding: 4px 4px 0 4px;
    padding: 0 6px 3px 2px;
    min-width: 210px;
    // max-width: 270px;
    // max-width: 600px;
    // width: 300px;

    h2, h3, h4 { margin:0; line-height: 1;}

    h2 {font-size: 16px;}
    h3 {font-size: 14px;}

    transition: transform .2s;
}
.Package:hover {
    transform: scale(1.5);
}

.pipeline {
    // --size: 12px;
    border: 1px solid #eeeeee;
    border-radius: 2px;
    font-family: Verdana, sans-serif;
    font-size: calc(2px + var(--size));
    line-height: 1;
    display: inline-block;
    height: calc(2px + var(--size));
    padding: 0;
    //padding: 1px 2px 0 2px;
    // padding: 4px;
    //width: 20px;
    text-align: center;
    color: white;

    &.success {
        // display: none;
        //border: 2px solid green;
        //border-radius: 0;
        //background-color: var(--good-color);
        // background-color: #55ff55;
        // color: rgb(88, 88, 88);
        color: white;
        border: none;
    }
    &.failed {
        //background-color: var(--bad-color);
        // background-color: red;
        color: red;
        background-color: white;
        //border-radius: 0;
    }
}

.score {
    text-decoration: none;
    font-size: 23px;
    line-height: 1;
    text-align: center;
}

.avatar {
    // max-width: 36px;
    max-width: 39px;
    max-height: 46px;
    height: 100%;
    width: 100%;
    image-rendering: -webkit-optimize-contrast;
    // width: 36px;
}
